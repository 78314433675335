import revive_payload_client_GwgBdeoAAh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HwCeIR0mY4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_22KnpxaHKi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MxogoSLO7Q from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_496epdgobM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_8iVHdznXQM from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_jlnw7ki69S from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Vyez65BiNw from "/opt/build/repo/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.31.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import slideovers_3sWEQXbede from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.31.0_t_5ibv2lrijdx22gkdtm2t6te464/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_iNk1OuCS0K from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.31.0_t_5ibv2lrijdx22gkdtm2t6te464/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_35EmpLBXBW from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.31.0_t_5ibv2lrijdx22gkdtm2t6te464/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_biaJZEQhc6 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_YmGJPF3Kwx from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@5.0.12_@types+node@22.10.9_sass@1.83.4_te_m6tuj57bo7sgo5gg6tfddcyudq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_fqjW4tISJZ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.9_magicast@0.3.5_rollup@4.31.0_sass@1.83._bs44f54jrzzljgkcigq2m5m6j4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import echo_Too0shxWI0 from "/opt/build/repo/plugins/echo.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/opt/build/repo/plugins/plyr.ts";
import stripe_6FZUdDHpIZ from "/opt/build/repo/plugins/stripe.ts";
import vue_final_modal_pML93k5qcp from "/opt/build/repo/plugins/vue-final-modal.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_GwgBdeoAAh,
  unhead_HwCeIR0mY4,
  router_22KnpxaHKi,
  payload_client_MxogoSLO7Q,
  check_outdated_build_client_496epdgobM,
  plugin_vue3_8iVHdznXQM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jlnw7ki69S,
  plugin_Vyez65BiNw,
  slideovers_3sWEQXbede,
  modals_iNk1OuCS0K,
  colors_35EmpLBXBW,
  plugin_client_biaJZEQhc6,
  plugin_YmGJPF3Kwx,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_fqjW4tISJZ,
  axios_QMFgzss1s4,
  echo_Too0shxWI0,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  stripe_6FZUdDHpIZ,
  vue_final_modal_pML93k5qcp,
  vue_query_wrmMkNpEpe
]