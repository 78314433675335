import { useAuthParticipent } from "@/store/authParticipants";
import { useMiddleware } from "@/store/middleware";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthParticipent();
  const config = useRuntimeConfig();
  const host = window.location.host;
  const subdomain = host.split(".")[0];
  const middleware = useMiddleware();
  const { $axios } = useNuxtApp();

  const defaultRoute = `/sessions/${to.params.id}/${auth.model_type}/${to.params.user_id}/`;

  //Check if route is public
  if (to.meta.noAuth) return;

  if (subdomain !== "app") {
    try {
      //  Check if subdomain exists
      await middleware.subdomain(subdomain);
    } catch (e) {
      console.log(e);
      await auth.logoutNoRoute();
      // Domain does not exist so redirect to primary login
      return navigateTo(`${config.public.primaryURL}/login`, {
        external: true,
      });
    }
  }
  if (!auth.meIsSuccess) {
    try {
      await auth.meRefetch();
    } catch {
      //
    }
  }
  
  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess) {
    try {
      await auth.refetch();
    } catch (error) {
      console.log(error);
      try {
        await auth.logoutNoRoute();
      } catch (e) {
        console.log(e);
      }
      //  if user is not logged or error redirect to participent login
      return navigateTo(defaultRoute);
    }
  }

  const session = ref(null);
  if (auth.isSuccess) {

    if (auth?.user?.auth_type === "KBA") {
      
      // Check if client has exceeded KBA or stripe identity attempts
      if((
          auth?.user?.kba_identity_verified !== "SUCCESS" &&
          (auth.user.identity_attempts ?? 0) >= 2
        )
          ||
        (
          !["SUCCESS", "verified"].includes(auth?.user?.stripe_identity_verified) &&
          (auth.user.stripe_identity_attempts ?? 0) >= 2
        )) {
        return navigateTo(`${defaultRoute}denied`);
      }

      if (to.path === `${defaultRoute}auth` && ["SUCCESS", "verified"].includes(auth?.user?.stripe_identity_verified)) {
        // Check if client has already passed KBA and stripe identity
        if (auth?.user?.kba_identity_verified === "SUCCESS") {
          return navigateTo(`${defaultRoute}documents`);
        }
        // client has already passed stripe identity
        return navigateTo(`${defaultRoute}details`);
      }
  
      if (to.path === `${defaultRoute}details` && !["SUCCESS", "verified"].includes(auth?.user?.stripe_identity_verified)) {
        // client has not passed stripe identity
        return navigateTo(`${defaultRoute}auth`);
      }
  
      if (to.path === `${defaultRoute}kba`) {
        // client has not passed stripe identity
        if (!["SUCCESS", "verified"].includes(auth?.user?.stripe_identity_verified)) {
          return navigateTo(`${defaultRoute}auth`);
        }
        // Check if client has already passed KBA and stripe identity
        if (auth?.user?.kba_identity_verified === "SUCCESS") {
          return navigateTo(`${defaultRoute}documents`);
        } 
      }

      // Check if client has already passed KBA and stripe identity or not
      const restrictedPaths = [
        `${defaultRoute}documents`,
        `${defaultRoute}waiting-room`,
        `${defaultRoute}room`,
      ];

      if (restrictedPaths.includes(to.path)) {
        if (!["SUCCESS", "verified"].includes(auth?.user?.stripe_identity_verified)) {
          return navigateTo(`${defaultRoute}auth`);
        }
        if (auth?.user?.kba_identity_verified !== "SUCCESS") {
          return navigateTo(`${defaultRoute}kba`);
        }
      }
    }

    try {
      session.value = await middleware.session(to.params.id);
    } catch (e) {
      await auth.logout();
      window.location.reload();
      return;
    }

    //Redirect to download page or switch if session is complete and user is client
    if (
      ![`${defaultRoute}switch`, `${defaultRoute}download`].includes(to.path) &&
      session.value?.session_status === "COMPLETED" &&
      auth.model_type === "client"
    ) {
      return navigateTo(`${defaultRoute}switch`);
    }

    if (
      from.path === defaultRoute &&
      auth?.model_type !== "client" &&
      auth.user?.session?.metadata?.onboarding === "completed"
    ) {
      navigateTo(`${defaultRoute}waiting-room`);
    }

    if (auth.user?.model_id !== auth.me_auth?.id) {
      await auth.logout();
      window.location.reload();
      return;
    }

    // Allow route access
    return;
  }
});
