import { useAuthParticipent } from "@/store/authParticipants";
import { useMiddleware } from "@/store/middleware";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthParticipent();
  const config = useRuntimeConfig();
  const middleware = useMiddleware();

  const defaultRoute = `/sessions/${to.params.id}/${auth.model_type}/${to.params.user_id}`;

  //Check if route is public
  if (to.meta.noAuth) return;

  if (auth.subdomain !== "app") {
    try {
      //  Check if subdomain exists
      await middleware.subdomain(auth.subdomain);
    } catch (e) {
      await auth.logoutNoRoute();
      // Domain does not exist so redirect to primary login
      return navigateTo(`${config.public.primaryURL}/login`, {
        external: true,
      });
    }
  }

  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess) {
    try {
      await auth.refetch();
    } catch (error) {
      await auth.logout();
      //  if user is not logged or error redirect to participent login
      return navigateTo(defaultRoute);
    }
  }
});
